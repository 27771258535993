import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import { Col, Form } from "react-bootstrap";
import Footer from '../components/Footer';
import FechPostDeleteAccount from "../APP_RESERVATION_FECH_DATA/FechPostDeleteAccount";
import ValidationFormField from "../utils/ValidationFormField";
import history from "../components/history";
import AuthService from "../services/auth.service";

class ProfileDeletePage extends React.Component {

    emptyItem = {
        passwordCurrent: ''
    };


    constructor(props) {
        super(props);
        this.state = {
            title: "RezerwujKort.pl - Profil",
            item: this.emptyItem,
            restDeleteAcountData: {
                successful: false,
                status: '',
                message: '',
                data: []
            },
            isError: {
                passwordCurrent: ''
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = this.state.title

    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        let item = { ...this.state.item };

        item[name] = value;
        this.setState({ item: item });

        this.fieldValidation(name, value);
    }

    fieldValidation(name, value, compareValue) {

        let isError = { ...this.state.isError };

        isError.editResponse = '';

        switch (name) {
            case "passwordCurrent":
                isError.passwordCurrent = ValidationFormField.validPassword("Hasło", value)
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
        this.state.isError[name] = isError[name];
    }

    async handleSubmit(e) {

        e.preventDefault();

        for (var key in this.state.item) {
            this.fieldValidation(key, this.state.item[key]);
        }

        var response;
        if (ValidationFormField.formValid(this.state.isError)) {
            response = await FechPostDeleteAccount(this.state.item);
            if (!response.successful) {
                if (response.message == 'Wrong Password') {

                    let item = { ...this.state.item };
                    item["password"] = '';
                    this.setState({ item: item });

                    let isError = { ...this.state.isError };
                    isError["passwordCurrent"] = "Błędne hasło!";
                    this.setState({ isError: isError });
                }  else {
                    let item = { ...this.state.item };
                    item["password"] = '';
                    this.setState({ item: item });

                    let isError = { ...this.state.isError };
                    isError["editResponse"] = "Wystąpił błąd, spróbuj ponownie";
                    this.setState({ isError: isError });
                }
            } else {
                AuthService.logout();
                history.push('/info', "Konto zostało usunięte.");
                window.location.reload();
            }
        }
    }


    render() {

        const { item } = this.state;
        const { isError } = this.state;

        return (

            <div>

                <Belt />

                <BeltLogged />

                <div id="content">
                    <div class="container">
                        <Form onSubmit={this.handleSubmit}>

                            <Form.Row>

                                <Form.Group as={Col} controlId="formGridName">
                                    <Form.Label>Hasło<span style={{ color: '#86b606' }}>*</span></Form.Label>
                                    <Form.Control
                                        className={isError.passwordCurrent.length > 0 ? "is-invalid form-control" : "form-control"}
                                        id="passwordCurrent"
                                        name="passwordCurrent"
                                        type="password"
                                        value={item.passwordCurrent || ''}
                                        onChange={this.handleChange}
                                    />
                                    {isError.passwordCurrent.length > 0 && (
                                        <div>
                                            <br />
                                            <div className="alert alert-danger" role="alert">{isError.passwordCurrent}</div>
                                        </div>
                                    )}
                                </Form.Group>

                            </Form.Row>

                            <div id="register-confirm">

                                <div class="left">
                                    <div class="register-confirm-alert">Pole oznaczone <span style={{ color: '#86b606' }}>*</span> są wymagane</div>
                                    <div class="register-confirm-regulations">
                                        <Form.Group controlId="formBasicCheckbox">
                                            <input type="submit" name="name" value="Usuń konto" />
                                        </Form.Group>

                                    </div>

                                </div>

                            </div>

                        </Form>

                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}
export default ProfileDeletePage;
