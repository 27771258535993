import React, { Suspense } from "react";

import { registerLocale } from "react-datepicker";
import { Spinner } from "react-bootstrap";
import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";
import queryString from "query-string";
import pl from "date-fns/locale/pl"; // the locale you want

import Footer from "../components/Footer";
import CreatLinkSerch from "../utils/CreatLinkSerch";
import history from "../components/history";

import BeltClub from "../components/BeltClub";
import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";
import FechGetReservationOnlineInfoSummaryUtils from "../fechData/FechGetReservationOnlineInfoSummaryUtils";

import "react-datepicker/dist/react-datepicker.css";
import DateTimeNow from "../utils/DateTimeNow";

const CreateReservationOnlineForm = React.lazy(() =>
  import("../forms/CreateReservationOnlineForm")
);

class ClubReservationOnlineConfirmPage extends React.Component {
  constructor(props) {
    super(props);
    this.printAdresse = this.printAdresse.bind(this);
  }

  day = "";
  courtId = "";
  hour = "";

  state = {
    startDate: new Date(),
    dataClubInfo: [],
    restClubData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restReservationUtilsData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
  };

  printAdresse() {
    return { __html: this.state.restClubData.data.descriptionShort };
  }

  async componentDidMount() {
    document.title = "Rezerwacja kortu - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.setState({
      restClubData: await FechGetClubLongDescBySlug(
        this.props.match.params.slug
      ),
    });

    this.day = this.urlValue.day;
    this.courtId = this.urlValue.court;
    this.hour = this.urlValue.hour;
    const values = new Map();
    if (this.day !== "") values.set("day", this.day);
    if (this.courtId !== "") values.set("courtId", parseInt(this.courtId));
    if (this.hour !== "") values.set("hour", this.hour);

    const deafulValues = [];

    var findEventsUrl = CreatLinkSerch("", values, deafulValues);
    this.setState({
      restReservationUtilsData: await FechGetReservationOnlineInfoSummaryUtils(
        this.props.match.params.slug,
        findEventsUrl
      ),
    });
  }

  render() {
    var reservationInfo = "";
    if (
      this.state.restReservationUtilsData.successful === false &&
      this.state.restReservationUtilsData.message !== ""
    ) {
      if (
        this.state.restReservationUtilsData.message ===
        "Rezerwację online dostępne są tylko dla zalogowanych użytkowników"
      ) {
        history.push("/login");
        window.location.reload();
      }
      if (
        this.state.restReservationUtilsData.message ===
        "Rezerwację online dostępne są tylko dla użytkowników z potwierdzonym numerem telefonu."
      ) {
        history.push("/profile/weryfikacja_numeru_telefonu");
        window.location.reload();
      }
      reservationInfo = (
        <div
          class="tenis-center-content-info"
          style={{ backgroundColor: "red", color: "white", fontSize: "19px" }}
        >
          {this.state.restReservationUtilsData.message}
        </div>
      );
    }

    var noAdverb = [];
    noAdverb.push(<div class="tenis-content-info">Brak ogłoszeń</div>);

    var beltClub = "";
    if (this.state.restClubData.successful) {
      beltClub = (
        <BeltClub
          dataClubInfo={this.state.restClubData.data}
          currentPage="reservation1"
        />
      );
    }

    var reservationForm;
    if (this.state.restReservationUtilsData.successful) {
      reservationForm = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <CreateReservationOnlineForm
              restReservationUtilsData={
                this.state.restReservationUtilsData.data
              }
              hourStart={this.hour}
              reservationDate={this.day}
              courtId={this.courtId}
              club={this.props.match.params.slug}
            />
          </Suspense>
        </div>
      );
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage tennis-center">
              <div class="head">
                <img src={`/images/login.png`} alt="" />
                <p>Korty Tenisowe</p>
              </div>
              <div class="main-subpage-content">
                {beltClub}

                <div class="tenis-center-content">
                  <ss dangerouslySetInnerHTML={this.printAdresse()} />

                  {reservationInfo}

                  {reservationForm}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default ClubReservationOnlineConfirmPage;
